<template>
  <div v-show="show">
    <div ref="table7_1" class="card_main" style="display: flex; align-items: center">
      <div class="echarts_area CHARTS_BGC" style="width: 60%">
        <div
          id="chartColumnInvestorStructure"
          ref="chartColumnInvestorStructure"
          style="width: 100%; height: 425px"></div>
      </div>
      <div style="margin: 30px 0px 10px 0px; width: 40%; position: relative">
        <div class="down1-wrap">
          <Download
            echartRefName="chartColumnInvestorStructure"
            :dButtonsDownloads="dButtonsDownloads"
            :excel_info="excel_info1" />
        </div>
        <el-table
          class="table1 t1"
          :header-cell-style="styleBindFun"
          :data="InvestorStructureTableData">
          <el-table-column align="center" prop="财报期" label="财报期" width="180">
          </el-table-column>
          <el-table-column align="center" prop="机构持有比例" label="机构持有比例">
            <template slot-scope="scope">
              <span>{{ scope.row.机构持有比例.toFixed(2) + '%' }} </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="个人持有比例" label="个人持有比例">
            <template slot-scope="scope">
              <span>{{ scope.row.个人持有比例.toFixed(2) + '%' }} </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="内部持有比例" label="内部持有比例">
            <template slot-scope="scope">
              <!-- <span>{{ scope.row.内部持有比例 + '%' }} </span> -->
              <span>{{ scope.row.内部持有比例.toFixed(4) + '%' }} </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--份额和占比  -->
    <div class="card_main">
      <div class="big_title" style="display: flex; align-items: center">
        选择财报期
        <CSelect
          :mini="true"
          :clearable="false"
          @CSelect_select_val="fnSelect_select_val_chart1"
          :options="annualreportDATA"
          :default="annualreportDATA_chart1"></CSelect>
        <CSelect
          :mini="true"
          :clearable="false"
          @CSelect_select_val="fnSelect_select_annualreport_list_value"
          :options="annualreport_list"
          :default="annualreport_list_value"></CSelect>
        <div class="down-wrap">
          <Download
            echartRefName="chartColumnShareAndProportion"
            :dButtonsDownloads="dButtonsDownloads"
            :excel_info="excel_info2" />
        </div>
      </div>
      <div class="echarts_area CHARTS_BGC">
        <div v-show="chartColumnShareAndProportion_X.length == 0" style="margin: auto">
          <el-empty description="暂无数据"></el-empty>
        </div>
        <div
          v-show="chartColumnShareAndProportion_X.length > 0"
          v-loading="loading_ShareAndProportion"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          id="chartColumnShareAndProportion"
          ref="chartColumnShareAndProportion"
          style="width: 100%; height: 425px"></div>
      </div>
      <div class="big_title">
        基金前十名流通份额持有人
        <div class="down1-wrap">
          <Download
            echartRefName="table1"
            :dButtonsDownloads="dButtonsDownloads"
            :excel_info="excel_info3" />
        </div>
      </div>
      <div ref="table1">
        <el-table
          class="table1"
          :class="theme == 'dark' ? 'dark_mark' : ''"
          :header-cell-style="styleBindFun"
          :data="ShareAndProportionableDataByFree"
          :summary-method="getSummaries"
          show-summary>
          <el-table-column align="center" prop="rank" sortable label="排名" width="80">
          </el-table-column>
          <el-table-column align="left" prop="holder_name" sortable width="800" label="持有人名称">
          </el-table-column>
          <el-table-column align="center" prop="object_type" sortable label="投资人类型">
          </el-table-column>
          <el-table-column align="center" prop="holder_units" sortable label="持有份额(份)">
            <template slot-scope="scope">
              <span>{{ scope.row.holder_units.toLocaleString() }} </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="holder_proportion" sortable label="占总份额比例(%)">
            <template slot-scope="scope">
              <span>{{ scope.row.holder_proportion + '%' }} </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="big_title">
        基金前十名非流通份额持有人
        <div class="down1-wrap">
          <Download
            echartRefName="table2"
            :dButtonsDownloads="dButtonsDownloads"
            :excel_info="excel_info4" />
        </div>
      </div>
      <div ref="table2">
        <el-table
          class="table1"
          :class="theme == 'dark' ? 'dark_mark' : ''"
          :header-cell-style="styleBindFun"
          :data="ShareAndProportionableDataByRestricted"
          :summary-method="getSummaries"
          show-summary>
          <el-table-column align="center" prop="rank" label="排名" width="70"> </el-table-column>
          <el-table-column align="left" prop="holder_name" width="800" label="持有人名称">
          </el-table-column>
          <el-table-column align="center" prop="object_type" label="投资人类型"> </el-table-column>
          <el-table-column align="center" prop="holder_units" label="持有份额(份)">
            <template slot-scope="scope">
              <span>{{ scope.row.holder_units.toLocaleString() }} </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="holder_proportion" label="占总份额比例(%)">
            <template slot-scope="scope">
              <span>{{ scope.row.holder_proportion + '%' }} </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import CSelects from '@/components/Basic/CSelects';
import CSelect from '@/components/Basic/CSelect';
import { fnReturnTableStyle, fnDownloadBgc } from '@/utils/util';
import Download from '@/components/Basic/Download';

import html2canvas from 'html2canvas';
export default {
  components: {
    CSelect,
    CSelects,
    Download,
  },
  props: {
    show: Boolean,
  },
  watch: {
    show(val) {
      if (val) {
        this.styleBindFun();
        this.fnGetchartColumnInvestorStructure();
        this.fnGetchartColumnShareAndProportion();
        this.fnGetchartAnnualreport('h');
      }
    },
    currentTheme(newVal, oldVal) {
      this.styleBindFun();
      this.$nextTick(() => {
        this.initChartColumnInvestorStructure();
        this.initChartColumnShareAndProportion();
      });
    },
  },
  data() {
    return {
      excel_info1: {
        excel_data: [],
        json_fields: {
          财报期: '财报期',
          机构持有比例: '机构持有比例',
          个人持有比例: '个人持有比例',
          内部持有比例: '内部持有比例',
          总持有人数: '总持有人数',
        },
        overviewName: '投资者持股比例',
      },
      excel_info2: {
        excel_data: [],
        json_fields: {
          name: 'object_type',
          流通: 'free_holder_units',
          非流通: 'restricted_holder_units',
        },
        overviewName: '财报投资者持股比例',
      },
      excel_info3: {
        excel_data: [],
        json_fields: {
          排名: 'rank',
          持有人名称: 'holder_name',
          投资人类型: 'object_type',
          '持有份额(份)': 'holder_units',
        },
        overviewName: '财报期基金前十名流通份额持有人',
      },
      excel_info4: {
        excel_data: [],
        json_fields: {
          排名: 'rank',
          持有人名称: 'holder_name',
          投资人类型: 'object_type',
          '持有份额(份)': 'holder_units',
        },
        overviewName: '财报期基金前十名非流通份额持有人',
      },
      //下载
      dButtonsDownloads: [
        {
          id: '241047',
          name: '下载图片',
        },
        {
          id: '241047',
          name: '下载表格',
        },
      ],
      dValuationTable1: [],
      json_fields1: {},

      theme: '',
      //投资者结构
      chartColumnInvestorStructure: null,
      InvestorStructureTableData: [],
      dStackingReturnData2_X: [],
      dStackingReturnData2_JG: [],
      dStackingReturnData2_FH: [],
      dStackingReturnData2_ZT: [],
      dStackingReturnData2_RS: [],

      //份额和占比

      loading_ShareAndProportion: false,
      annualreportDATA: [
        {
          label: '中报',
          value: 'h',
        },
        {
          label: '年报',
          value: 'a',
        },
      ],
      annualreportDATA_chart1: 'h',
      annualreport_list: [],
      annualreport_list_value: '',

      chartColumnShareAndProportion: null,
      chartColumnShareAndProportion_X: [],
      chartColumnShareAndProportion_JG: [],
      chartColumnShareAndProportion_FH: [],
      ShareAndProportionableDataByFree: [],
      ShareAndProportionableDataByRestricted: [],

      //old
      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,
      ROOTFONTFIZE: 0,
      TABPANEHEIGHT: 0,
    };
  },
  methods: {
    //下载
    fnButtonDL7_1(val) {
      if (val == '下载图片') {
        this.FnDownload7_1();
      } else {
        document.getElementById('downloadExcel7_1').click();
      }
    },
    FnDownload7_1() {
      let h = this.$refs.table7_1.scrollHeight;
      let w = this.$refs.table7_1.scrollWidth;
      let bgColor = fnDownloadBgc(this.sCurrentTheme);
      console.log('FnDownload7_1', bgColor);
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.table7_1, {
        async: true,
        height: h + 20,
        windowHeight: h + 20,
        width: w,
        backgroundColor: bgColor,
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '动态估值'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },
    //获取中报期列表或者年报期列表
    async fnGetchartAnnualreport(select_val) {
      this.annualreport_list = [];
      let code = window.sessionStorage.getItem('CURRENTCODE');
      const { data } = await this.$https.get(
        '/api/single_reit_fundstatus?code=' + code + '&rp_type=' + select_val
      );
      if (data.data.length <= 0) {
        return;
      }
      var init_list = Object.entries(data.data[0]);
      init_list.forEach((item) => {
        if (item[0] != 'index' && item[0].indexOf('H2') == -1) {
          this.annualreport_list.push({
            label: item[0],
            value: item[0],
          });
        }
      });
      if (this.annualreport_list.length > 0) {
        this.annualreport_list_value = this.annualreport_list[0]['value'];
        this.fnGetchartColumnShareAndProportion(this.annualreport_list_value);
      }
    },
    async fnSelect_select_val_chart1(val) {
      this.annualreportDATA_chart1 = val;
      console.log('fnSelect_select_val_chart1', val);
      this.fnGetchartAnnualreport(val);
    },
    async fnSelect_select_annualreport_list_value(val) {
      this.annualreport_list_value = val;
      this.fnGetchartColumnShareAndProportion(val);
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          if (index === 3) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index].toLocaleString();
          } else if (index === 4) {
            sums[index] = values.reduce((prev, curr) => {
              return prev + curr;
              // return parseFloat(prev)  + parseFloat(curr) ;
            }, 0);
            sums[index] = sums[index].toFixed(2) + '%';
          }
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    //投资者结构
    async fnGetchartColumnInvestorStructure() {
      let code = window.sessionStorage.getItem('CURRENTCODE');
      const { data } = await this.$https.get('/api/v2/fin_holder_proportion?code=' + code);
      this.InvestorStructureTableData = data.data;

      this.dValuationTable1 = [];
      this.InvestorStructureTableData.forEach((item) => {
        var one = {
          财报期: item.财报期,
          机构持有比例: item.机构持有比例,
          个人持有比例: item.个人持有比例,
          内部持有比例: item.内部持有比例,
          总持有人数: item.总持有人数,
        };
        one['机构持有比例'] = one['机构持有比例'].toFixed(2) + '%';
        one['个人持有比例'] = one['个人持有比例'].toFixed(2) + '%';
        one['内部持有比例'] = one['内部持有比例'].toFixed(4) + ' %';
        this.dValuationTable1.push(one);
      });

      console.log('TTTTTTTTT', this.dValuationTable1);

      this.json_fields1 = {
        财报期: '财报期',
        机构持有比例: '机构持有比例',
        个人持有比例: '个人持有比例',
        内部持有比例: '内部持有比例',
        总持有人数: '总持有人数',
      };

      this.dStackingReturnData2_X = [];
      this.dStackingReturnData2_JG = [];
      this.dStackingReturnData2_FH = [];
      this.dStackingReturnData2_ZT = [];
      this.dStackingReturnData2_RS = [];

      let dataTmp = data.data;

      this.excel_info1.excel_data = dataTmp;
      console.clear();
      console.log(dataTmp);

      for (let index = 0; index < dataTmp.length; index++) {
        this.dStackingReturnData2_X.push(dataTmp[index]['财报期']);
        this.dStackingReturnData2_JG.push(dataTmp[index]['机构持有比例']);
        this.dStackingReturnData2_FH.push(dataTmp[index]['个人持有比例']);
        this.dStackingReturnData2_ZT.push(dataTmp[index]['内部持有比例']);
        this.dStackingReturnData2_RS.push(dataTmp[index]['总持有人数']);
      }

      this.$nextTick(() => {
        this.initChartColumnInvestorStructure();
      });
    },

    initChartColumnInvestorStructure(val) {
      if (!val) {
        this.chartColumnInvestorStructure = this.$echarts.init(
          this.$refs.chartColumnInvestorStructure
        );
      } else {
        this.chartColumnInvestorStructure = this.$echarts.init(
          this.$refs.chartColumnInvestorStructure
        );
      }

      let dStackingReturnData2_X = this.dStackingReturnData2_X;
      let dStackingReturnData2_JG = this.dStackingReturnData2_JG;
      let dStackingReturnData2_FH = this.dStackingReturnData2_FH;
      let dStackingReturnData2_ZT = this.dStackingReturnData2_ZT;
      let dStackingReturnData2_RS = this.dStackingReturnData2_RS;

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let option = {
        // 标题设置
        title: [
          {
            text: '投资者持股持股比例',
            textStyle: {
              // 标题文字样式设置
              color: OPTIONDATA.title.textStyle.color,
              fontSize: OPTIONDATA.title.textStyle.fontSize,
            },
            left: 'center',
            top: OPTIONDATA.title.top,
          },
        ],
        legend: {
          show: true,
          orient: 'horizontal',
          bottom: this.ROOTFONTFIZE * (38 / 144),
          left: this.ROOTFONTFIZE * (180 / 144),
          align: 'left',
          icon: 'roundRect',
          itemGap: this.ROOTFONTFIZE * (20 / 144),
          itemHeight: this.ROOTFONTFIZE * (10 / 144),
          itemWidth: this.ROOTFONTFIZE * (10 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: 'inherit',
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: OPTIONDATA.legend.textStyle.fontSize,
          },
          data: [
            {
              name: '个人持有比例',
              icon: 'rect',
            },
            {
              name: '机构持有比例',
              icon: 'rect',
            },
            {
              name: '内部持有比例',
              icon: 'rect',
            },
            {
              name: '总持有人数',
              icon: 'rect',
            },
          ],
        },

        grid: {
          left: this.ROOTFONTFIZE * (20 / 144),
          bottom: this.ROOTFONTFIZE * (60 / 144),
          right: this.ROOTFONTFIZE * (35 / 144),
          top: this.ROOTFONTFIZE * (80 / 144),
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          formatter: function (args) {
            // console.log("args",args);
            let str = '';
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              let val = node.value;
              if (node.seriesName == '机构持有比例') {
                val = val.toFixed(2) + '%';
              }
              if (node.seriesName == '个人持有比例') {
                val = val.toFixed(2) + '%';
              }
              if (node.seriesName == '内部持有比例') {
                val = val.toFixed(4) + '%';
              }
              if (node.seriesName == '总持有人数') {
                val = val + '人';
              }
              str += '<br>';
              str += `${node.marker}${node.seriesName}：${val}`;

              return str;
            });
            return str;
          },
        },

        xAxis: {
          type: 'category',
          data: dStackingReturnData2_X,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff',
            },
            onZero: true, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
            margin: OPTIONDATA.xAxis.axisLabel.margin,
            // rotate: 25,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: [
          {
            // scale: true,
            position: 'left',
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
              //x轴刻度相关设置
              alignWithLabel: true,
            },
            axisLabel: {
              fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              // formatter: "{value}%", //y轴百分比
              formatter: function (val) {
                // console.log("val",val);
                return val.toFixed(2) + '%';
              },
            },
            splitLine: OPTIONDATA.yAxis.splitLine,
          },
          {
            type: 'value',
            // name: '总持有人数',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
              show: true,
              color: 'transparent',
            },
            position: 'right',
            alignTicks: true,
            splitLine: OPTIONDATA.yAxis.splitLine,
          },
        ],
        series: [
          {
            name: '机构持有比例',
            type: 'bar',
            stack: 'all',
            data: dStackingReturnData2_JG,
            itemStyle: {
              color: '#5acac6',
            },
            label: {
              show: false,
              position: 'inside',
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              color: '#fefefe',
              formatter: (value) => value.value.toFixed(2) + '%',
            },
            z: 1,
          },
          {
            name: '个人持有比例',
            type: 'bar',
            stack: 'all',
            data: dStackingReturnData2_FH,
            itemStyle: {
              color: '#5e5ce6',
            },
            label: {
              show: false,
              position: 'top',
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              color: OPTIONDATA.legend.textStyle.color,
              formatter: (value) => value.value.toFixed(2) + '%',
            },
            z: 1,
          },
          {
            name: '内部持有比例',
            type: 'bar',
            stack: 'all2',
            data: dStackingReturnData2_ZT,
            itemStyle: {
              color: '#196b24',
            },
            label: {
              show: false,
              position: 'top',
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              color: OPTIONDATA.legend.textStyle.color,
              formatter: (value) => value.value.toFixed(4) + '%',
            },
            barGap: '-100%',
            // barCategoryGap: '0%',
            z: 2,
          },

          {
            name: '总持有人数',
            yAxisIndex: 1,
            type: 'line',
            // stack: 'all',
            data: dStackingReturnData2_RS,
            itemStyle: {
              color: '#E98532',
            },
            z: 3,
            label: {
              show: false,
              position: 'top',
              fontSize:
                dStackingReturnData2_RS.length < 15
                  ? this.ROOTFONTFIZE * (14 / 144)
                  : this.ROOTFONTFIZE * (10 / 144),
              color: OPTIONDATA.legend.textStyle.color,
            },
          },
        ],
      };

      this.chartColumnInvestorStructure.setOption(option);
      this.chartColumnInvestorStructure.resize();
    },
    //投资者结构end

    //份额和占比
    async fnGetchartColumnShareAndProportion(val) {
      this.loading_ShareAndProportion = true;
      let code = window.sessionStorage.getItem('CURRENTCODE');
      let rp_period = val;
      const { data } = await this.$https.get(
        '/api/v2/fin_data_holder?code=' + code + '&rp_period=' + rp_period
      );
      this.ShareAndProportionableDataByFree = data.data['free_float_units'];
      this.excel_info3.excel_data = data.data['free_float_units'];
      this.ShareAndProportionableDataByRestricted = data.data['restricted_units'];
      this.excel_info4.excel_data = data.data['restricted_units'];

      this.chartColumnShareAndProportion_X = [];
      this.chartColumnShareAndProportion_JG = [];
      this.chartColumnShareAndProportion_FH = [];
      if (data.data['chart'].length == 0) {
        this.loading_ShareAndProportion = false;
        this.chartColumnShareAndProportion && this.chartColumnShareAndProportion.clear();
        return;
      }

      let dataTmp = data.data;

      var share_proportion_new_obj = {};
      dataTmp['chart'].forEach((item) => {
        if (share_proportion_new_obj[item.object_type] == null) {
          share_proportion_new_obj[item.object_type] = {
            free_holder_units: 0,
            restricted_holder_units: 0,
            object_type: item.object_type,
          };
        }
        if (item.rank_type == 'free_float_units') {
          share_proportion_new_obj[item.object_type]['free_holder_units'] = item.holder_units;
        }
        if (item.rank_type == 'restricted_units') {
          share_proportion_new_obj[item.object_type]['restricted_holder_units'] = item.holder_units;
        }
      });
      var share_proportion_new_list = Object.values(share_proportion_new_obj);

      for (let index = 0; index < share_proportion_new_list.length; index++) {
        this.chartColumnShareAndProportion_X.push(share_proportion_new_list[index]['object_type']);
        this.chartColumnShareAndProportion_JG.push(
          share_proportion_new_list[index]['free_holder_units']
        );
        this.chartColumnShareAndProportion_FH.push(
          share_proportion_new_list[index]['restricted_holder_units']
        );
      }
      this.excel_info2.excel_data = share_proportion_new_list;

      this.$nextTick(() => {
        this.initChartColumnShareAndProportion();
      });
    },

    initChartColumnShareAndProportion(val) {
      if (!val) {
        this.chartColumnShareAndProportion = this.$echarts.init(
          this.$refs.chartColumnShareAndProportion
        );
      } else {
        this.chartColumnShareAndProportion = this.$echarts.init(
          this.$refs.chartColumnShareAndProportion
        );
      }

      let chartColumnShareAndProportion_X = this.chartColumnShareAndProportion_X;
      let chartColumnShareAndProportion_JG = this.chartColumnShareAndProportion_JG;
      let chartColumnShareAndProportion_FH = this.chartColumnShareAndProportion_FH;

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let option = {
        // 标题设置
        // title: [
        //   {
        //     text: '投资者持股持股比例',
        //     textStyle: {
        //       // 标题文字样式设置
        //       color: OPTIONDATA.title.textStyle.color,
        //       fontSize: OPTIONDATA.title.textStyle.fontSize,
        //     },
        //     left: 'center',
        //     top: OPTIONDATA.title.top,
        //   },
        // ],
        legend: {
          show: true,
          orient: 'horizontal',
          bottom: this.ROOTFONTFIZE * (38 / 144),
          left: this.ROOTFONTFIZE * (680 / 144),
          align: 'left',
          icon: 'roundRect',
          itemGap: this.ROOTFONTFIZE * (20 / 144),
          itemHeight: this.ROOTFONTFIZE * (10 / 144),
          itemWidth: this.ROOTFONTFIZE * (10 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: 'inherit',
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: OPTIONDATA.legend.textStyle.fontSize,
          },
          data: [
            {
              name: '流通',
              icon: 'rect',
            },
            {
              name: '非流通',
              icon: 'rect',
            },
          ],
        },

        grid: {
          left: this.ROOTFONTFIZE * (20 / 144),
          bottom: this.ROOTFONTFIZE * (60 / 144),
          right: this.ROOTFONTFIZE * (35 / 144),
          top: this.ROOTFONTFIZE * (80 / 144),
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          formatter: function (args) {
            // console.log("args",args);
            let str = '';
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              let val = node.value;
              val = val.toLocaleString();
              str += '<br>';
              str += `${node.marker}${node.seriesName}：${val}`;

              return str;
            });
            return str;
          },
        },

        xAxis: {
          type: 'category',
          data: chartColumnShareAndProportion_X,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff',
            },
            onZero: true, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
            margin: OPTIONDATA.xAxis.axisLabel.margin,
            // rotate: 25,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: [
          {
            // scale: true,
            position: 'left',
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
              //x轴刻度相关设置
              alignWithLabel: true,
            },
            axisLabel: {
              fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              // formatter: "{value}%", //y轴百分比
              // formatter: function (val) {
              //   // console.log("val",val);
              //   return val.toFixed(2) + '%';
              // },
            },
            splitLine: OPTIONDATA.yAxis.splitLine,
          },
        ],
        series: [
          {
            name: '流通',
            type: 'bar',
            stack: 'all',
            data: chartColumnShareAndProportion_JG,
            itemStyle: {
              color: '#5acac6',
            },
            label: {
              show: false,
              position: 'inside',
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              color: '#fefefe',
              formatter: (value) => value.value.toFixed(2) + '%',
            },
            z: 1,
          },
          {
            name: '非流通',
            type: 'bar',
            stack: 'all',
            data: chartColumnShareAndProportion_FH,
            itemStyle: {
              color: '#5e5ce6',
            },
            label: {
              show: false,
              position: 'top',
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              color: OPTIONDATA.legend.textStyle.color,
              formatter: (value) => value.value.toFixed(2) + '%',
            },
            z: 1,
          },
        ],
      };

      this.chartColumnShareAndProportion.setOption(option);
      this.chartColumnShareAndProportion.resize();
      this.loading_ShareAndProportion = false;
    },

    styleBindFun() {
      let val = this.$store.state.theme == 'dark' ? true : false;
      this.theme = this.$store.state.theme;
      return fnReturnTableStyle(val);
    },
    funResize() {
      this.fnGetWidth();
    },
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      let OFFSETHEIGHT = element.offsetHeight;
      this.TABPANEHEIGHT = OFFSETHEIGHT - 135;
      return;
    },
  },
  computed: {
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
        '--TABPANEHEIGHT': this.TABPANEHEIGHT + 'px',
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  mounted() {
    window.addEventListener('resize', this.funResize);
    this.fnGetWidth();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.funResize);
    this.chartColumnInvestorStructure && this.chartColumnInvestorStructure.dispose();
    this.chartColumnShareAndProportion && this.chartColumnShareAndProportion.dispose();
  },
};
</script>

<style lang="less" scoped>
@vh: 1 /10.8vh;

.down-wrap {
  margin-left: auto;
}

.big_title {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: var(--chart_title);
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
  display: flex;
  justify-content: space-between;
}

.card_main:first-child {
  border-bottom: 1px solid white;
}

.dark_mark {
  /deep/ .el-table__footer tr td {
    background: #343742 !important;
    font-size: 16px;
    color: #dadada !important;
    border-top: 1px solid #4c4d5f !important;
  }
}

.t1 {
  margin-top: calc(var(--ROOTFONTFIZE) * (55 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (45 / 144));
  max-height: calc(var(--ROOTFONTFIZE) * (390 / 144));

  /deep/ .el-table__body .el-table__row .el-table__cell {
    height: calc(var(--ROOTFONTFIZE) * (30 / 144));
  }
}

.downloadBtns {
  position: absolute;
  right: 0;
  top: calc(var(--ROOTFONTFIZE) * (-40 / 144));
}
</style>
