<template>
  <div class="calculator">
    <div class="CARD_tit">经营数据对应业绩计算器</div>
    <div class="title">
      <div class="downloadBtns">
        <CButtonsDownload
          :dButtonsDownloads="dButtonsDownloads"
          @fnButtondownload="fnButtonDL6_1" />
        <span style="display: none">
          <download-excel
            id="downloadExcel_hway"
            :data="echart_data"
            :fields="json_fields"
            :name="overviewName">
            <i class="el-icon-download"></i>
          </download-excel>
        </span>
      </div>
    </div>
    <div class="CARD_body">
      <div class="calculator_cav">
        <span @click="fnOpenDialog" class="iconfont icon-fangda icon-fangda2"></span>
        <div
          v-loading="loading"
          element-loading-text="数据量巨大，正在计算中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          id="calculator"
          ref="calculator"
          style="width: 100%; height: 425px"></div>
      </div>
      <div class="calculator_content">
        <CButtons
          @Cbtns="assetSelect"
          class="CARD_body_tabs"
          :dButtons1="asset_name_list"
          :defaultVal="asset_default" />
          <div class="row">
          <div class="name"></div>
          <div class="input_case">取值</div>
          <div class="input_case">
            上限
            <el-tooltip
                  class="item"
                  effect="dark"
                  content="上限,历史数据90分位数据"
                  placement="top">
                  <i class="el-icon-question" style="color: rgb(64, 158, 255)"></i>
                </el-tooltip>
          </div>
          <div class="input_case">
            下限
            <el-tooltip
                  class="item"
                  effect="dark"
                  content="下限,历史数据10分位数据"
                  placement="top">
                  <i class="el-icon-question" style="color: rgb(64, 158, 255)"></i>
                </el-tooltip>
          </div>
        </div>
        <div class="row">
          <div class="name">综合等效租金</div>
          <div class="input_case">
            <el-input
              @change="
                validateNumber(
                  'default_config',
                  'asset_config',
                  active_asset_index,
                  'com_eff_rent',
                  'mean'
                )
              "
              v-model="
                default_config.asset_config[active_asset_index].com_eff_rent.mean
              "></el-input>
          </div>
          <div class="input_case">
            <el-input
              @change="
                validateNumber(
                  'default_config',
                  'asset_config',
                  active_asset_index,
                  'com_eff_rent',
                  'max'
                )
              "
              v-model="default_config.asset_config[active_asset_index].com_eff_rent.max"></el-input>
          </div>
          <div class="input_case">
            <el-input
              @change="
                validateNumber(
                  'default_config',
                  'asset_config',
                  active_asset_index,
                  'com_eff_rent',
                  'min'
                )
              "
              v-model="default_config.asset_config[active_asset_index].com_eff_rent.min"></el-input>
          </div>
        </div>
        <div class="row">
          <div class="name">平均出租率水平</div>
          <div class="input_case">
            <el-input
              @change="
                validateNumber(
                  'default_config',
                  'asset_config',
                  active_asset_index,
                  'avg_occ',
                  'mean'
                )
              "
              v-model="default_config.asset_config[active_asset_index].avg_occ.mean"></el-input>
          </div>
          <div class="input_case">
            <el-input
              @change="
                validateNumber(
                  'default_config',
                  'asset_config',
                  active_asset_index,
                  'avg_occ',
                  'max'
                )
              "
              v-model="default_config.asset_config[active_asset_index].avg_occ.max"></el-input>
          </div>
          <div class="input_case">
            <el-input
              @change="
                validateNumber(
                  'default_config',
                  'asset_config',
                  active_asset_index,
                  'avg_occ',
                  'min'
                )
              "
              v-model="default_config.asset_config[active_asset_index].avg_occ.min"></el-input>
          </div>
        </div>
        <div class="row">
          <div class="name">综合等效租金增幅</div>
          <div class="input_case">
            <el-input
              @change="
                validateNumber(
                  'default_config',
                  'asset_config',
                  active_asset_index,
                  'comeff_rent_yoy',
                  'mean'
                )
              "
              v-model="
                default_config.asset_config[active_asset_index].comeff_rent_yoy.mean
              "></el-input>
          </div>
          <div class="input_case">
            <el-input
              @change="
                validateNumber(
                  'default_config',
                  'asset_config',
                  active_asset_index,
                  'comeff_rent_yoy',
                  'max'
                )
              "
              v-model="
                default_config.asset_config[active_asset_index].comeff_rent_yoy.max
              "></el-input>
          </div>
          <div class="input_case">
            <el-input
              @change="
                validateNumber(
                  'default_config',
                  'asset_config',
                  active_asset_index,
                  'comeff_rent_yoy',
                  'min'
                )
              "
              v-model="
                default_config.asset_config[active_asset_index].comeff_rent_yoy.min
              "></el-input>
          </div>
        </div>
        <div class="row_line"></div>
        <div class="row">
          <div class="name">EBITDA Margin</div>
          <div class="input_case">
            <el-input
              @change="validateNumberByMargin('default_config', 'ebitda_margin_list', 'mean')"
              v-model="default_config.ebitda_margin_list.mean"></el-input>
          </div>
          <div class="input_case">
            <el-input
              @change="validateNumberByMargin('default_config', 'ebitda_margin_list', 'max')"
              v-model="default_config.ebitda_margin_list.max"></el-input>
          </div>
          <div class="input_case">
            <el-input
              @change="validateNumberByMargin('default_config', 'ebitda_margin_list', 'min')"
              v-model="default_config.ebitda_margin_list.min"></el-input>
          </div>
        </div>
        <div class="row">
          <div class="name">Div Margin</div>
          <div class="input_case">
            <el-input
              @change="validateNumberByMargin('default_config', 'div_margin_list', 'mean')"
              v-model="default_config.div_margin_list.mean"></el-input>
          </div>
          <div class="input_case">
            <el-input
              @change="validateNumberByMargin('default_config', 'div_margin_list', 'max')"
              v-model="default_config.div_margin_list.max"></el-input>
          </div>
          <div class="input_case">
            <el-input
              @change="validateNumberByMargin('default_config', 'div_margin_list', 'min')"
              v-model="default_config.div_margin_list.min"></el-input>
          </div>
        </div>
        <div class="button_case">
          <el-button class="calculator_btn" @click="getOperationExpressway(true)">计算</el-button>
        </div>
      </div>
    </div>
    <CDialog
      id="dialog1"
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible">
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog">
        <div>
          <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown">
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div :style="{ height: height_chart }">
          <div ref="ipoDialogRef" style="width: 100%; height: 100%"></div>
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import { fnDownloadBgc } from '@/utils/util';
import CButtons from '@/components/Basic/CButtons';
import CButtonsDownload from '@/components/Basic/CButtons_download';
import CDialog from '@/components/Basic/CDialog';
import html2canvas from 'html2canvas';

export default {
  components: {
    CButtons,
    CButtonsDownload,
    CDialog,
  },
  data() {
    return {
      //放大
      dialogVisible: false,
      width_dialog: '80%',
      height_dialog: '73vh',
      height_chart: '72vh',
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: '5vh',

      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,
      ROOTFONTFIZE: 0,
      CODE: '',
      loading: false,
      dButtonsDownloads: [
        {
          id: '241072',
          name: '下载图片',
        },
        {
          id: '241073',
          name: '下载表格',
        },
      ],
      json_fields: {
        rp_period: 'rp_period',
        'EBITDA ttm走势及预测': 'ebitda_ttm',
        'EBITDA ttm走势及预测上限': 'distributable_amount(period)_ttm_max',
        'EBITDA ttm走势及预测下限': 'distributable_amount(period)_ttm_min',
        可供分配金额走势及预测: 'distributable_amount(period)_ttm',
        可供分配金额走势及预测上限: 'distributable_amount(period)_ttm_max',
        可供分配金额走势及预测下限: 'distributable_amount(period)_ttm_min',
        对应分派率_ttm走势及预测: 'div_yield(annulized)',
        对应分派率_ttm走势及预测上限: 'div_yield(annulized)_max',
        对应分派率_ttm走势及预测下限: 'div_yield(annulized)_min',
      },
      overviewName: 'EBITDA 及可供分配金额情况预测',
      echart_data: [],

      dStackingReturnData2_X: [],
      dStackingReturnData2_JG: [],
      dStackingReturnData2_FH: [],
      dStackingReturnData2_RS: [],
      asset_default: '',
      default_config: {
        asset_config: [
          {
            name: '',
            com_eff_rent: {
              max: 0,
              min: 0,
              mean: 0,
            },
            avg_occ: {
              max: 0,
              min: 0,
              mean: 0,
            },
            comeff_rent_yoy: {
              max: 0,
              min: 0,
              mean: 0,
            },
          },
        ],
        div_margin_list: {
          max: 0,
          min: 0,
          mean: 0,
        },
        ebitda_margin_list: {
          max: 0,
          min: 0,
          mean: 0,
        },
      },
      chartCalculator: null,
    };
  },
  created() {},
  mounted() {
    this.fnGetWidth();
    window.addEventListener('resize', this.funResize);
    this.reload();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.funResize);
    this.chartCalculator && this.chartCalculator.dispose();
  },
  methods: {
    //放大start
    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initChartMainPerformance(true);
      });
    },
    fnDropdown(val) {
      let conf = this.$store.state.popUpWindowConfi[val];
      this.width_dialog = conf.width_dialog;
      this.top_dialog = conf.top_dialog;
      this.height_dialog = conf.height_dialog;
      this.popUpWindowIndex = val;
      this.height_chart = conf.height_chart;
      this.$nextTick(() => {
        this.initChartMainPerformance(true);
      });
    },
    //放大end
    assetSelect(val) {
      this.asset_default = val;
    },
    async getOperationExpressway(flag) {
      this.loading = true;
      var queryString = '';
      if (flag) {
        let data = {
          config_data: JSON.stringify(this.formatNumbers(this.default_config)),
        };
        queryString = Object.keys(data)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
          .join('&');
      }

      const { data } = await this.$https.post(
        '/api/v2/get_fin_data_quarterly_operation_industrial_park?code=' + this.CODE,
        queryString,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      this.loading = false;
      if (data.code !== 200) return this.$message.error(data.msg);
      //表格处理start
      var echart_data = data.data.list.concat(data.data.yugu_list);
      var new_echart_data = {};
      echart_data.forEach((item) => {
        if (new_echart_data[item['rp_period']] == null) {
          new_echart_data[item['rp_period']] = {};
        }
        new_echart_data[item['rp_period']]['rp_period'] = item['rp_period'];
        new_echart_data[item['rp_period']][item['key']] =
          item['value'] == undefined ? item['mean'] : item['value'];
        if (item['max'] != undefined) {
          new_echart_data[item['rp_period']][item['key'] + '_max'] = item['max'];
        }
        if (item['min'] != undefined) {
          new_echart_data[item['rp_period']][item['key'] + '_min'] = item['min'];
        }
      });
      this.echart_data = Object.values(new_echart_data);
      //表格处理end

      this.dStackingReturnData2_X = [];
      this.dStackingReturnData2_JG = [];
      this.dStackingReturnData2_FH = [];
      this.dStackingReturnData2_RS = [];

      this.default_config = this.formatNumbersInArrayOrObjectToString(data.data.default_config);

      if (this.default_config.asset_config && this.default_config.asset_config.length > 0) {
        this.asset_default = this.default_config.asset_config[0].name;
      }

      let dataTmp = data.data.list;
      this.veh_rev_list = data.data.veh_rev_list;
      this.vehd_rev_yoy_list = data.data.vehd_rev_yoy_list;
      this.ebitda_margin_list = data.data.ebitda_margin_list;
      this.div_margin_list = data.data.div_margin_list;

      var period_list = {};
      dataTmp.forEach((item) => {
        period_list[item.rp_period] = {};
        if (item.key == 'ebitda_ttm') {
          this.dStackingReturnData2_JG.push(item.value);
        }
        if (item.key == 'distributable_amount(period)_ttm') {
          this.dStackingReturnData2_FH.push(item.value);
        }
        if (item.key == 'div_yield(annulized)') {
          this.dStackingReturnData2_RS.push(item.value);
        }
      });

      //这里开始预估
      this.dStackingReturnData2_X = Object.keys(period_list);

      var period_list_2 = {};
      this.dStackingReturnData2_JG_2 = [];
      this.dStackingReturnData2_FH_2 = [];
      this.dStackingReturnData2_RS_2 = [];

      this.dStackingReturnData2_JG_limit = [];
      this.dStackingReturnData2_FH_limit = [];
      data.data.yugu_list.forEach((item) => {
        period_list_2[item.rp_period] = {};
        if (item.key == 'ebitda_ttm') {
          this.dStackingReturnData2_JG_2.push(item.mean);
          this.dStackingReturnData2_JG_limit.push({
            max: item.max,
            min: item.min,
          });
        }
        if (item.key == 'distributable_amount(period)_ttm') {
          this.dStackingReturnData2_FH_2.push(item.mean);
          this.dStackingReturnData2_FH_limit.push({
            max: item.max,
            min: item.min,
          });
        }
        if (item.key == 'div_yield(annulized)') {
          this.dStackingReturnData2_RS_2.push(item.mean);
        }
      });

      //预测
      this.dStackingReturnData2_X = this.dStackingReturnData2_X.concat(Object.keys(period_list_2));
      //ebitda_ttm预测
      this.dStackingReturnData2_JG_yuce = [];
      this.dStackingReturnData2_JG.forEach((item) => {
        this.dStackingReturnData2_JG_yuce.push('-');
      });
      this.dStackingReturnData2_JG_yuce.pop();
      this.dStackingReturnData2_JG_yuce.push({
        value: this.dStackingReturnData2_JG[this.dStackingReturnData2_JG.length - 1],
        name: 'none',
      });
      this.dStackingReturnData2_JG_yuce = this.dStackingReturnData2_JG_yuce.concat(
        this.dStackingReturnData2_JG_2
      );
      //ebitda_ttm面积
      this.dStackingReturnData2_JG_yuce_area = [];
      var num = 0;
      var JG_array = [];
      JG_array.push([
        this.dStackingReturnData2_X.length - this.dStackingReturnData2_JG_limit.length - 1,
        this.dStackingReturnData2_JG[this.dStackingReturnData2_JG.length - 1],
      ]);
      this.dStackingReturnData2_JG_limit.forEach((item) => {
        var v =
          this.dStackingReturnData2_JG_yuce[
            this.dStackingReturnData2_JG_yuce.length - (Object.keys(period_list_2).length - num) - 1
          ];
        if (typeof v == 'object') {
          v = v.value;
        }
        JG_array.push([
          this.dStackingReturnData2_X.length - (Object.keys(period_list_2).length - num),
          item.max,
        ]);
        num++;
      });
      num--;
      JSON.parse(JSON.stringify(this.dStackingReturnData2_JG_limit))
        .reverse()
        .forEach((item) => {
          var v =
            this.dStackingReturnData2_JG_yuce[
              this.dStackingReturnData2_JG_yuce.length -
                (Object.keys(period_list_2).length - num) -
                1
            ];
          if (typeof v == 'object') {
            v = v.value;
          }
          JG_array.push([
            this.dStackingReturnData2_X.length - (Object.keys(period_list_2).length - num),
            item.min,
          ]);
          num--;
        });
      this.dStackingReturnData2_JG_yuce_area.push(JG_array);

      //distributable_amount(period)_ttm预测
      this.dStackingReturnData2_FH_yuce = [];
      this.dStackingReturnData2_FH.forEach((item) => {
        this.dStackingReturnData2_FH_yuce.push('-');
      });
      this.dStackingReturnData2_FH_yuce.pop();
      this.dStackingReturnData2_FH_yuce.push({
        value: this.dStackingReturnData2_FH[this.dStackingReturnData2_FH.length - 1],
        name: 'none',
      });
      this.dStackingReturnData2_FH_yuce = this.dStackingReturnData2_FH_yuce.concat(
        this.dStackingReturnData2_FH_2
      );
      //distributable_amount(period)_ttm面积
      this.dStackingReturnData2_FH_yuce_area = [];
      num = 0;
      var FH_array = [];
      FH_array.push([
        this.dStackingReturnData2_X.length - this.dStackingReturnData2_FH_limit.length - 1,
        this.dStackingReturnData2_FH[this.dStackingReturnData2_FH.length - 1],
      ]);
      this.dStackingReturnData2_FH_limit.forEach((item) => {
        var v =
          this.dStackingReturnData2_FH_yuce[
            this.dStackingReturnData2_FH_yuce.length - (Object.keys(period_list_2).length - num) - 1
          ];
        if (typeof v == 'object') {
          v = v.value;
        }
        FH_array.push([
          this.dStackingReturnData2_X.length - (Object.keys(period_list_2).length - num),
          item.max,
        ]);
        num++;
      });
      num--;
      JSON.parse(JSON.stringify(this.dStackingReturnData2_FH_limit))
        .reverse()
        .forEach((item) => {
          var v =
            this.dStackingReturnData2_FH_yuce[
              this.dStackingReturnData2_FH_yuce.length -
                (Object.keys(period_list_2).length - num) -
                1
            ];
          if (typeof v == 'object') {
            v = v.value;
          }
          FH_array.push([
            this.dStackingReturnData2_X.length - (Object.keys(period_list_2).length - num),
            item.min,
          ]);
          num--;
        });
      this.dStackingReturnData2_FH_yuce_area.push(FH_array);

      //div_yield(annulized)预测
      this.dStackingReturnData2_RS_yuce = [];
      this.dStackingReturnData2_RS.forEach((item) => {
        this.dStackingReturnData2_RS_yuce.push('-');
      });
      this.dStackingReturnData2_RS_yuce = this.dStackingReturnData2_RS_yuce.concat(
        this.dStackingReturnData2_RS_2
      );

      this.$nextTick(() => {
        this.initChartMainPerformance();
      });
    },
    initChartMainPerformance(dialog_flag) {
      if (dialog_flag) {
        if (!this.echart_dialog_instance) {
          this.echart_dialog_instance = this.$echarts.init(this.$refs.ipoDialogRef);
        }
        this.echart_dialog_instance.clear();
      }

      this.chartCalculator = this.$echarts.init(this.$refs.calculator);

      let dStackingReturnData2_X = this.dStackingReturnData2_X;
      let dStackingReturnData2_JG = this.dStackingReturnData2_JG;
      let dStackingReturnData2_FH = this.dStackingReturnData2_FH;
      let dStackingReturnData2_RS = this.dStackingReturnData2_RS;

      let dStackingReturnData2_JG_limit = this.dStackingReturnData2_JG_limit;
      let dStackingReturnData2_JG_yuce = this.dStackingReturnData2_JG_yuce;
      let dStackingReturnData2_JG_yuce_area = this.dStackingReturnData2_JG_yuce_area;

      let dStackingReturnData2_FH_yuce = this.dStackingReturnData2_FH_yuce;
      let dStackingReturnData2_FH_yuce_area = this.dStackingReturnData2_FH_yuce_area;

      let dStackingReturnData2_RS_yuce = this.dStackingReturnData2_RS_yuce;

      var series_dStackingReturnData2_JG_yuce_area = [];
      dStackingReturnData2_JG_yuce_area.forEach((area) => {
        series_dStackingReturnData2_JG_yuce_area.push(
          //EBITDA ttm走势及预测面积
          {
            name: 'EBITDA ttm走势及预测',
            type: 'custom',
            renderItem: function (params, api) {
              if (params.context.rendered) {
                return;
              }
              params.context.rendered = true;
              let points = [];
              area.forEach((item) => {
                points.push(api.coord(item));
              });
              return {
                type: 'polygon',
                transition: ['shape'],
                shape: {
                  points: points,
                },
                style: api.style({
                  fill: 'rgba(101,231,233,.2)',
                  stroke: 'transparent',
                }),
              };
            },
            z: 0,
            clip: true,
            data: area,
          }
        );
      });

      var series_dStackingReturnData2_FH_yuce_area = [];
      dStackingReturnData2_FH_yuce_area.forEach((area) => {
        series_dStackingReturnData2_FH_yuce_area.push(
          //可供分配金额走势及预测面积
          {
            name: '可供分配金额走势及预测',
            type: 'custom',
            renderItem: function (params, api) {
              if (params.context.rendered) {
                return;
              }
              params.context.rendered = true;
              let points = [];
              area.forEach((item) => {
                points.push(api.coord(item));
              });
              return {
                type: 'polygon',
                transition: ['shape'],
                shape: {
                  points: points,
                },
                style: api.style({
                  fill: 'rgba(129,134,235,.2)',
                  stroke: 'transparent',
                }),
              };
            },
            z: 0,
            clip: true,
            data: area,
          }
        );
      });

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        toolbox: {
          // 工具箱按钮
          right: 20,
          top: 10,
          itemSize: 18,
          iconStyle: {
            borderColor: OPTIONDATA.toolbox.iconStyle.borderColor,
          },
          feature: {
            dataZoom: {}, // 区域缩放
          },
        },
        // 标题设置
        title: [
          {
            text: 'EBITDA 及可供分配金额情况预测',
            textStyle: {
              // 标题文字样式设置
              color: OPTIONDATA.title.textStyle.color,
              fontSize: OPTIONDATA.title.textStyle.fontSize,
            },
            left: 'center',
            top: OPTIONDATA.title.top,
          },
        ],
        legend: [
          {
            show: true,
            orient: 'horizontal',
            top: this.ROOTFONTFIZE * (65 / 144),
            left: this.ROOTFONTFIZE * (160 / 144),
            align: 'left',
            icon: 'roundRect',
            itemGap: this.ROOTFONTFIZE * (20 / 144),
            itemHeight: this.ROOTFONTFIZE * (5 / 144),
            itemWidth: this.ROOTFONTFIZE * (35 / 144),
            padding: [0, 0, 0, 0],
            lineStyle: {
              color: 'inherit',
            },
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: OPTIONDATA.legend.textStyle.fontSize,
            },
            data: [
              {
                name: 'EBITDA ttm走势及预测',
                icon: 'rect',
              },
              {
                name: '可供分配金额走势及预测',
                icon: 'rect',
              },
            ],
          },
          {
            show: true,
            orient: 'horizontal',
            top: this.ROOTFONTFIZE * (65 / 144),
            left: this.ROOTFONTFIZE * (590 / 144),
            align: 'left',
            icon: 'roundRect',
            itemGap: this.ROOTFONTFIZE * (20 / 144),
            itemHeight: this.ROOTFONTFIZE * (10 / 144),
            itemWidth: this.ROOTFONTFIZE * (10 / 144),
            padding: [0, 0, 0, 0],
            lineStyle: {
              color: 'inherit',
            },
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: OPTIONDATA.legend.textStyle.fontSize,
            },
            data: [
              {
                name: '对应分派率_ttm走势及预测',
                icon: 'rect',
              },
            ],
          },
        ],

        grid: {
          left: this.ROOTFONTFIZE * (20 / 144),
          bottom: this.ROOTFONTFIZE * (0 / 144),
          right: this.ROOTFONTFIZE * (35 / 144),
          top: this.ROOTFONTFIZE * (120 / 144),
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          formatter: function (args) {
            // console.log("args",args);
            let str = '';
            str += `${args[0].axisValueLabel}`;

            var seriesName = null;
            var marker = null;
            var seriesName_num = 0;
            var market = '';
            args.forEach((node) => {
              let val = node.value;
              if (val == '-' || node.name == 'none') {
                return;
              }
              if (node.axisValue == dStackingReturnData2_X[dStackingReturnData2_X.length-dStackingReturnData2_JG_limit.length-1] && node.componentSubType == 'custom') {
                return;
              }
              if (seriesName == node.seriesName) {
                seriesName_num++;
              }
              if (seriesName_num == 1 && typeof val == 'object') {
                market = '上限';
                val = val[1];
              }
              if (seriesName_num == 2 && typeof val == 'object') {
                market = '下限';
                val = val[1];
              }

              if (seriesName_num > 2 && typeof val == 'object') {
                return;
              }

              if (seriesName != node.seriesName) {
                seriesName = node.seriesName;
                marker = node.marker;
                seriesName_num = 0;
                market = '';
              }

              if (seriesName == '对应分派率_ttm走势及预测') {
                val = (Number(val) * 100).toFixed(2) + '%';
              } else {
                val = (Number(val) / 10000).toFixed(2) + '万元';
              }

              var color = '';
              if (
                seriesName.indexOf('EBITDA ttm走势及预测') != -1 ||
                seriesName.indexOf('可供分配金额走势及预测') != -1
              ) {
                var color_list = marker.split(';');
                color = color_list[color_list.length - 2];
                marker = `<span style="display:inline-block;margin-right:4px;border-radius:2px;width:20px;height:5px;${color}"></span>`;
              } else {
                var color_list = marker.split(';');
                color = color_list[color_list.length - 2];
                marker = `<span style="display:inline-block;margin-right:4px;border-radius:2px;width:10px;height:10px;${color}"></span>`;
              }

              str += '<br>';
              str += `${marker}${seriesName + market}：${val}`;

              return str;
            });
            return str;
          },
        },

        xAxis: {
          type: 'category',
          data: dStackingReturnData2_X,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff',
            },
            onZero: true, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize - 2,
            margin: OPTIONDATA.xAxis.axisLabel.margin,
            rotate: 45,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: [
          {
            // scale: true,
            name: '单位：万元',
            nameTextStyle: {
              color: OPTIONDATA.title.textStyle.color,
              fontSize: OPTIONDATA.title.textStyle.fontSize,
            },
            position: 'left',
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
              //x轴刻度相关设置
              alignWithLabel: true,
            },
            axisLabel: {
              fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              // formatter: "{value}%", //y轴百分比
              formatter: function (val) {
                // console.log("val",val);
                return Number(val) / 10000;
              },
            },
            splitLine: OPTIONDATA.yAxis.splitLine,
          },
          {
            // scale: true,
            position: 'right',
            type: 'value',
            show: false,
            axisLine: {
              show: false,
            },
            axisTick: {
              // show: false,
              show: true,
              //x轴刻度相关设置
              alignWithLabel: true,
            },
            axisLabel: {
              fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
              // show: false,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (val) {
                return val;
              },
            },
            splitLine: OPTIONDATA.yAxis.splitLine,
            boundaryGap: [0, '1000%'],
          },
        ],
        series: [
          {
            name: 'EBITDA ttm走势及预测',
            type: 'line',
            data: dStackingReturnData2_JG,
            itemStyle: {
              color: '#65E7E9',
            },
            label: {
              show: false,
              position: 'inside',
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              color: '#fefefe',
              formatter: (value) => value.value.toFixed(2) + '%',
            },
            z: 1,
          },
          {
            name: 'EBITDA ttm走势及预测',
            type: 'line',
            data: dStackingReturnData2_JG_yuce,
            itemStyle: {
              color: '#65E7E9',
            },
            lineStyle: {
              color: '#65E7E9',
              width: 2,
              type: 'dashed',
            },
            label: {
              show: false,
              position: 'inside',
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              color: '#fefefe',
              formatter: (value) => value.value.toFixed(2) + '%',
            },
            z: 1,
          },
          ...series_dStackingReturnData2_JG_yuce_area,

          {
            name: '可供分配金额走势及预测',
            type: 'line',
            data: dStackingReturnData2_FH,
            itemStyle: {
              color: '#8186EB',
            },
            label: {
              show: false,
              position: 'top',
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              color: OPTIONDATA.legend.textStyle.color,
              formatter: (value) => value.value.toFixed(2) + '%',
            },
            z: 1,
          },
          {
            name: '可供分配金额走势及预测',
            type: 'line',
            data: dStackingReturnData2_FH_yuce,
            itemStyle: {
              color: '#8186EB',
            },
            lineStyle: {
              color: '#8186EB',
              width: 2,
              type: 'dashed',
            },
            label: {
              show: false,
              position: 'top',
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              color: OPTIONDATA.legend.textStyle.color,
              formatter: (value) => value.value.toFixed(2) + '%',
            },
            z: 1,
          },
          ...series_dStackingReturnData2_FH_yuce_area,

          {
            name: '对应分派率_ttm走势及预测',
            type: 'bar',
            // stack: 'total',
            clip: true,
            // yAxisIndex: 1,
            data: dStackingReturnData2_RS,
            itemStyle: {
              color: '#8186EB',
            },
            z: 0,
            label: {
              show: false,
              position: 'top',
              fontSize:
                dStackingReturnData2_RS.length < 15
                  ? this.ROOTFONTFIZE * (14 / 144)
                  : this.ROOTFONTFIZE * (10 / 144),
              color: OPTIONDATA.legend.textStyle.color,
            },
            barMinHeight: 10,
            barGap: '-100%',
          },
          {
            name: '对应分派率_ttm走势及预测',
            type: 'bar',
            // stack: 'total',
            clip: true,
            // yAxisIndex: 1,
            data: dStackingReturnData2_RS_yuce,
            itemStyle: {
              color: '#F2F2F2',
            },
            z: 0,
            label: {
              show: false,
              position: 'top',
              fontSize:
                dStackingReturnData2_RS.length < 15
                  ? this.ROOTFONTFIZE * (14 / 144)
                  : this.ROOTFONTFIZE * (10 / 144),
              color: OPTIONDATA.legend.textStyle.color,
            },
            barMinHeight: 10,
          },
        ],
      };

      if (dialog_flag) {
        this.echart_dialog_instance.setOption(option);
        this.echart_dialog_instance.resize();
      }

      this.chartCalculator.setOption(option);
      this.chartCalculator.resize();
    },
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },
    funResize() {
      this.fnGetWidth();
      this.chartCalculator&&this.chartCalculator.resize();
      this.echart_dialog_instance&&this.echart_dialog_instance.resize();
    },

    fnGetWidth() {
      this.ROOTFONTFIZE = localStorage.getItem('ROOTFONTFIZE');
    },
    validateNumberByMargin(p1, p2, p3) {
      var is_percentage = false;
      if (this[p1][p2][p3].indexOf('%') != -1) {
        is_percentage = true;
      }
      this[p1][p2][p3] = this[p1][p2][p3].replace(/[^\d.-]/g, '');

      if (this[p1][p2][p3] === '') {
        this[p1][p2][p3] = 0;
      }
      this[p1][p2][p3] = parseFloat(this[p1][p2][p3]);
      if (is_percentage) {
        this[p1][p2][p3] = parseFloat(this[p1][p2][p3] / 100).toFixed(4);
      }
      if (Number(this[p1][p2][p3]) >= 1) {
        this[p1][p2][p3][p4][p5] = 1;
      }
    },
    validateNumber(p1, p2, p3, p4, p5) {
      var is_percentage = false;
      if (this[p1][p2][p3][p4][p5].indexOf('%') != -1) {
        is_percentage = true;
      }
      this[p1][p2][p3][p4][p5] = this[p1][p2][p3][p4][p5].replace(/[^\d.-]/g, '');

      if (this[p1][p2][p3][p4][p5] === '') {
        this[p1][p2][p3][p4][p5] = 0;
      }
      this[p1][p2][p3][p4][p5] = parseFloat(this[p1][p2][p3][p4][p5]);
      if (is_percentage) {
        this[p1][p2][p3][p4][p5] = parseFloat(this[p1][p2][p3][p4][p5] / 100).toFixed(4);
      }
      if (p4 != 'vehd_rev_yoy_list') {
        return;
      }

      if (Number(this[p1][p2][p3][p4][p5]) <= -1) {
        this[p1][p2][p3][p4][p5] = -1;
      }
    },
    formatNumbersInArrayOrObjectToString(data) {
      data.asset_config.forEach((item) => {
        for (var key in item) {
          if (key == 'name' || key == 'gfa') {
            continue;
          }
          if (key == 'com_eff_rent') {
            for (var key2 in item[key]) {
              item[key][key2] = Number(item[key][key2]).toLocaleString();
            }
            continue;
          }
          for (var key2 in item[key]) {
            item[key][key2] = Number(item[key][key2] * 100).toFixed(2) + '%';
          }
        }
      });
      for (var key in data) {
        if (key == 'asset_config') {
          continue;
        }
        for (var key2 in data[key]) {
          data[key][key2] = Number(data[key][key2] * 100).toFixed(2) + '%';
        }
      }
      return data;
    },
    formatNumbers(data) {
      data.asset_config.forEach((item) => {
        for (var key in item) {
          if (key == 'name' || key == 'gfa') {
            continue;
          }
          for (var key2 in item[key]) {
            var is_percentage = false;
            item[key][key2] = item[key][key2] + '';
            if (item[key][key2].indexOf('%') != -1) {
              is_percentage = true;
            }
            item[key][key2] = item[key][key2].replace(/[^\d.-]/g, '');
            item[key][key2] = parseFloat(item[key][key2]);
            if (is_percentage) {
              item[key][key2] = parseFloat(parseFloat(item[key][key2] / 100).toFixed(4));
            }
          }
        }
      });
      for (var key in data) {
        if (key == 'asset_config') {
          continue;
        }
        for (var key2 in data[key]) {
          var is_percentage = false;
          data[key][key2] = data[key][key2] + '';
          if (data[key][key2].indexOf('%') != -1) {
            is_percentage = true;
          }
          data[key][key2] = data[key][key2].replace(/[^\d.-]/g, '');
          data[key][key2] = parseFloat(data[key][key2]);
          if (is_percentage) {
            data[key][key2] = parseFloat(parseFloat(data[key][key2] / 100).toFixed(4));
          }
        }
      }
      return data;
    },
    fnButtonDL6_1(val) {
      if (val == '下载图片') {
        this.downloadImg();
      } else {
        setTimeout(() => {
          document.getElementById('downloadExcel_hway').click();
        }, 0);
      }
    },
    downloadImg() {
      let h = this.$refs.calculator.scrollHeight;
      let w = this.$refs.calculator.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.calculator, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '主要车流量概览'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    reload() {
      this.CODE = window.sessionStorage.getItem('CURRENTCODE');
      this.getOperationExpressway();
    },
  },
  computed: {
    asset_name_list() {
      var arr = [];
      this.default_config.asset_config.forEach((item) => {
        arr.push(item.name);
      });
      return arr;
    },
    active_asset_index() {
      var index = this.default_config.asset_config.findIndex(
        (item) => item.name === this.asset_default
      );
      if (index < 0) {
        return 0;
      }
      return index;
    },
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
      };
    },
    currentTheme() {
      return this.$store.state.theme;
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
      fnDownloadBgc();
      this.initChartMainPerformance();
    },
  },
};
</script>

<style lang="less" scoped>
.calculator {
  .title {
    width: 98%;
    display: flex;
    justify-content: flex-end;
  }
  .CARD_tit {
    padding-left: calc(var(--ROOTFONTFIZE) * (22 / 144));
    border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
    font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
    font-weight: 700;
    letter-spacing: 0px;
    line-height: calc(var(--ROOTFONTFIZE) * (30 / 144));
    color: var(--chart_title);
  }
  .CARD_body {
    padding: 0 calc(var(--ROOTFONTFIZE) * (30 / 144));
    display: flex;
    justify-content: space-between;
    .calculator_cav {
      position: relative;
      width: 60%;
      height: 425px;
      .icon-fangda2 {
        right: 82px;
      }
    }
    .calculator_content {
      width: 40%;
      .row_right {
        font-size: calc(var(--ROOTFONTFIZE) * (14 / 144)) !important;
        .input_block {
          white-space: nowrap;
          text-align: right;
        }
      }
      .row {
        width: 100%;
        height: calc(var(--ROOTFONTFIZE) * (50 / 144));
        font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
        color: var(--el_tabs_item_color_active) !important;
        display: flex;
        align-items: center;
        .name {
          width: 30%;
          display: flex;
          align-items: center;
        }
        .input_case {
          width: 20%;
          display: flex;
          justify-content: center;
          align-items: center;
          /deep/ .el-input__inner {
            background-color: transparent !important;
            color: var(--el_tabs_item_color_active) !important;
            text-align: center;
          }
        }
      }
      .row_line {
        margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
        width: 100%;
        height: 1px;
        border-bottom: 1px dashed gray;
      }
      .button_case {
        width: 95%;
        display: flex;
        justify-content: flex-end;
        padding: calc(var(--ROOTFONTFIZE) * (30 / 144));
        box-sizing: border-box;
        /deep/ .el-button {
          width: calc(var(--ROOTFONTFIZE) * (150 / 144));
          background-color: var(--primary-color) !important;
          color: var(--table_bgc) !important;
        }
      }
    }
  }
}
</style>
